"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mui_rff_1 = require("mui-rff");
const react_1 = require("react");
const ZoneName = ({ name, required }) => {
    return ((0, jsx_runtime_1.jsx)(mui_rff_1.TextField, { label: "Name", name: name, required: required, helperText: "Add your zone name", sx: {
            minWidth: 300,
            maxWidth: 300,
            textAlign: 'center',
        } }));
};
exports.default = (0, react_1.memo)(ZoneName);
