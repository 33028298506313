"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBookingSummaryMessage = void 0;
const utils_1 = require("../../libs/utils");
const zone_1 = require("../zone");
const getBookingSummaryMessage = (booking, includeTime) => {
    let message = includeTime ? `${(0, utils_1.toHourAndMinute)(booking.from)} - ${(0, utils_1.toHourAndMinute)(booking.to)}, Working` : 'Working';
    if (booking.team) {
        message += ` in team "${booking.team.name}"`;
    }
    if (booking.location) {
        message += ` from the "${booking.location.name}"`;
    }
    if (booking.desks.length > 0) {
        message += ` at desk "${booking.desks.map(({ name }) => name).join(', ')}"`;
        const zones = booking.desks.flatMap(({ locationTags }) => locationTags).filter(({ tagType }) => tagType === zone_1.TAG_TYPE_LOCATION_ZONE);
        if (zones.length > 0) {
            const uniqueZones = Array.from(zones.reduce((map, zone) => map.set(zone.uniqueId, zone), new Map()).values());
            message += ` in "${uniqueZones.map(({ name }) => name).join(', ')}"`;
        }
    }
    return message;
};
exports.getBookingSummaryMessage = getBookingSummaryMessage;
