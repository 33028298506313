/**
 * @generated SignedSource<<cc6d4eb0b629c05267a81cdfb76fc922>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type organizationLocationsTab_query$data = {
  readonly organization: {
    readonly canModify: boolean;
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "organizationLocationsTab_query";
};
export type organizationLocationsTab_query$key = {
  readonly " $data"?: organizationLocationsTab_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"organizationLocationsTab_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "organizationLocationsTab_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "organizationId"
        }
      ],
      "concreteType": "OrganizationDetails",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canModify",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d0ea9805df10d62ee5991ab4ee5a88e5";

export default node;
